<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-modal
            ref="modalRef"
             size="lg"
             centered cancel-variant="outline-secondary" title-tag="div"
            @hidden="modalRowData = null"
          >
            <template #modal-title>
              Schema Faq
            </template>
            <template #default>
              <div>
              <b-col
            cols="12"
            class="mb-2"
          >

            <div class="border rounded p-2">
              <b-row
                v-for="(itemQ, index) in modalRowData.mainEntity"
                :key="index"
                no-body
                class="border rounded p-2"
              >

                <br>
                <b-card-text class="my-50" />

                

                <b-col md="6">
                  <b-form-group
                    label="Question "
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Q "
                    >

                      <b-form-input
                        id="alt-image"
                        v-model="itemQ.name"
                        disabled
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Answer"
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
 <b-form-textarea
                        id="alt-text"
                        disabled
                        v-model="itemQ.acceptedAnswer.text"
                      />
                 
                  </b-form-group>
                </b-col>

                <hr>
              </b-row>

             
            </div>
          </b-col>
                <!-- Add other row details as needed -->
              </div>
            </template>
            <template #modal-footer="{ cancel }">
              <!-- Close button -->
              <b-button @click="cancel">
                Close
              </b-button>
            </template>
          </b-modal>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
            "
          >
            <label>Show</label>
            <v-select
            data-testid="page-size"
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="perPageOptions"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
      
          <!-- Search -->
          <b-col
            cols="3"
            md="3"
            class="d-flex align-items-end justify-content-end mb-1 mb-md-0"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        striped
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="PostsList"
        :sort-by.sync="sort"
        show-empty
        empty-text="No matching records found"
      >
   

        <!-- Column: Actions -->
        <template
          #cell(actions)="data"
         
        >
          <a role="button" class="action"   @click="viewRowDetails(data.item)">
            
              <feather-icon 
              :data-testid="`show-item-${data.item.id}`"
              icon="EyeIcon" variant="primary" />
        
          </a>
          <a role="button" class="action"  @click="DeleteSchema(data.item.id)">
            <feather-icon 
            :data-testid="`delete-item-${data.item.id}`"
            icon="TrashIcon" />
          </a>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, reactive, computed,
} from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'


export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
  },
  

  setup() {
    const PostsList = ref([])
    const searchQueryTYPE = ref('')
    const TypeList = ref([{
      label: 'General Course', value: 'Course',
    },
    {
      label: 'Post', value: 'Post',
    },
    {
      label: 'Event', value: 'Event',
    }])
    const meta = reactive({})
    const perPageOptions = [25, 50, 100, 200]
    const perPage = ref(25)
    const totalCategories = ref(0)
    const sort = ref('')
    const SortArray = []
    const searchQuery = ref('')
    const currentPage = ref(1)
    watch([currentPage, perPage,
      searchQueryTYPE,
      sort], () => {
      GetAllPosts()
    })
    const GetAllPosts = () => {
      let data = null

      if (!SortArray.includes(sort.value) && sort.value != '') {
        SortArray.push(sort.value)
        data = SortArray.toString().replace('[', '')
      }

      store
        .dispatch('homepage/allSchemaFaq', {
          sort: data,
          'filter[search]': searchQuery.value,
          
          per_page: perPage.value,
          page: currentPage.value,

        })
        .then(response => {
          PostsList.value = response.data.data
          totalCategories.value = response.data.meta.total
        })
    }
    const refCourseListTable = ref()
    watch([currentPage, perPage, sort, searchQuery], () => {
      GetAllPosts()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      }
    })
    // }
    GetAllPosts()
 
    const tableColumns = [
      { key: 'id', lanel: 'ID', sortable: true },
      { key: 'post', label: 'post', sortable: true },
   

           { key: "actions" },

    ]
    // Ref for modal dialog
    const modalRef = ref(null)
    // Row data for modal
    const modalRowData = ref(null)

    // Function to handle the view action and open modal
    const viewRowDetails = rowData => {
        if(rowData.data){
           
  modalRowData.value = JSON.parse(rowData.data)
        }
    
      modalRef.value.show()
    }

    // Close modal function
    const closeModal = () => {
      modalRef.value.hide()
    }
     const DeleteSchema = (id) => {
      Vue.swal({
        icon: "warning",
        title: "Are You Sure?",
        confirmButtonText: "YES",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then((result) => {
        if (result.value) {
          store.dispatch("homepage/DeleteSchemaFaq", id).then((response) => {
           GetAllPosts()
            Vue.swal({
              title: "Schema Deleted ",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
        }
      });
    };
 
    return {
      DeleteSchema,
      modalRef,
      GetAllPosts,
      modalRowData,
      viewRowDetails,
      closeModal,
      tableColumns,
      searchQueryTYPE,
      TypeList,
      PostsList,
      searchQuery,
    
      meta,
      perPageOptions,
      totalCategories,
      currentPage,
      perPage,
      refCourseListTable,
      dataMeta,
      SortArray,
      sort,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.action {
    margin-left: 16px;
    color: #D69900 !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
