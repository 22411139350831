<template>
  <b-card title="Schema">
    <b-tabs>
    
    
 <b-tab active @click="$router.push('/faq-schema')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Add FAQ</span>
        </template>
      <AddSchemaFaq @add-course="handleAddCourse"/>
        <!-- <AllArticales></AllArticales> -->
      </b-tab>
     <b-tab @click="$router.push('/faq-schema-list')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span data-testid="schema-all-faq-tab"> FAQ List</span>
        </template>
             <SchemaFaq ref="schemaFaqRef"/>
        <!-- <AllArticales></AllArticales> -->
      </b-tab>
      
   
      
    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'


import AddSchemaFaq from './AddSchemaFaq.vue'

import SchemaFaq from './SchemaFaq.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
 
    SchemaFaq,

    AddSchemaFaq,

  },
  data() {
    return {

    }
  },
    methods: {
    handleAddCourse() {
      this.$refs.schemaFaqRef.GetAllPosts() // Call getAllPost function in SchemaFaq component
    }
  }

}
</script>
